<template>
  <div class="content">
    <div style="display: flex; justify-content: center">
      <div style="width: 1200px;padding: 20px 20px;display: flex;align-items: center">
        <img  class="log_img" src="~@/assets/hm_logo.png"/>
        <span style="color: black;font-size: 25px;font-weight: bold;font-family: monospace;margin-left: 10px">红轻停云坐席</span>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <div style="width: 1200px;margin-top:100px; display: flex;justify-content: space-between">
        <div>
          <img  class="login_img" src="~@/assets/cs.jpg"/>
        </div>
        <div style="flex-grow: 2;display: flex;justify-content: center;" >
          <div class="form">
            <div>登录</div>
            <a-form-model
                :model="form"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
              <a-form-model-item>
                <a-input size="large" v-model="form.username" placeholder="请输入用户名" @keyup.enter="sub">
                  <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-input size="large" v-model="form.password" type="password" placeholder="请输入密码" @keyup.enter="sub">
                  <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
                </a-input>
              </a-form-model-item>
              <a-alert v-if="loginResult" type="error" :message="loginResult" banner />
              <a-form-model-item>
                <a-button type="primary" style="width: 100%" @click="sub">
                  登录
                </a-button>
              </a-form-model-item>
            </a-form-model>
            <div style="text-align: right;margin-right: 5px">
              <a-popover placement="left" arrow-point-at-center>
                <template slot="content">
                  <div>请联系管理员重置</div>
                </template>
                <a>忘记密码?</a>
              </a-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute; bottom: 0; color: black; padding: 20px 0;display: flex;width: 100%;display: flex;flex-direction: column; justify-content: center;align-items: center">
      <div>
        <a-divider type="vertical" />
        <a href="#">红轻停云</a>
        <a-divider type="vertical" />
        <a href="#">红门智慧</a>
        <a-divider type="vertical" />
        <a href="#">红门</a>
      </div>
    </div>
  </div>
</template>

<script>
import { aesEncrypt } from '@/utis/crypto'
export default {
  name: 'Login',
  data: function() {
    return {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      loginResult:  undefined,
      form: {
        username: '',
        password: '',
        v: 'goNNc2XjduMPhD14sRrHTw==',
				code: 5179
      },
      contentCss : {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around'
      }
    }
  },
  methods: {
    sub () {
      this.form.password = aesEncrypt(this.form.password)
      this.$store.dispatch('Login', this.form).then(() => {
        this.$router.push({ path: '/' })
      }).catch(e => {
        this.loginResult = e.message
      })
      this.loginResult = undefined
    },
    info() {
      this.$message.info('请联系管理员重置');
    }
  }
}
</script>

<style scoped>
  .content {
    height: 100%;
    width: 100%;
    background-color: #F6F6F6
  }
  .form {
    padding: 30px 30px;
    width: 300px;
    background-color: white;
    border: 1px solid #f0f0f0;
  }
  .log_img {
    height: 50px;
    width: 50px;
  }
  .login_img {

  }
</style>